import {useState} from "react";

interface Props {
    onScan: (value: string) => (any)
}

export const DevScan = (props: Props) => {

    const [value, setValue] = useState('');

    return (

     <form onSubmit={(e) => {
         e.preventDefault();
         props.onScan(value)
     }}
           style={{}}
     >
         <input
          placeholder={'handmatige invoer'}
          value={value} type={'text'}
          onChange={(e) => setValue(e.target.value)}
          style={{padding: '.6rem', border: '1px solid #eee', borderRadius: '.2rem', fontSize: '1.4rem'}}
         />
     </form>
    )
}